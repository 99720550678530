import React from 'react';
import './index.css';


export default class Home extends React.Component {
     render() {
         return (
             <div class="welcomeHome">
                 <b>Welcome to Everhappy Commercial Corporation</b>
             </div>
         )
     }
 }
